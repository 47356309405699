import axios from "utils/axios";

import { setAlert } from "./alertAction";
import { CLEAR_ERRORS, GET_ERRORS, SET_CATEGORY } from "./types";

export const getCategories = () => dispatch => {
  axios
    .get('api/category')
    .then(res => {
      dispatch(setCategory(res.data.data));
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    })
};

export const updateCategory = (resolve, id, name, multiplier) => dispatch => {
  axios
    .put(`api/category/${id}`, { id, name, multiplier })
    .then(res => {
      dispatch(setCategory(res.data.data));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      resolve();
    })
};

export const deleteCategory = (resolve, id) => dispatch => {
  axios
    .delete(`api/category/${id}`)
    .then(res => {
      dispatch(setCategory(res.data.data));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      resolve();
    })
};

export const createCategory = (resolve, name, multiplier) => dispatch => {
  axios
    .post('api/category/', { name, multiplier })
    .then(res => {
      dispatch(setCategory(res.data.data));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err.response.data });
      resolve();
    })
};

export const setCategory = obj => {
  return {
    type: SET_CATEGORY,
    payload: obj
  }
};