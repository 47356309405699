import axios from "utils/axios";

import { SET_DRAWERLIST, SET_IS_OTHER_USER } from "./types";

// Get Sidebar Items
export const setDrawerList = (id) => dispatch => {
  axios
    .get(`/api/client/menus${id != null ? `?id=${id}` : ''}`)
    .then(res => {
      dispatch(setItemList(res.data.data))
      dispatch(setIsOtherUser(res.data.isOtherUser))
    })
    .catch(err => {
      dispatch(setItemList({}))
    });
};

export const setItemList = list => {
  return {
    type: SET_DRAWERLIST,
    payload: list
  };
};
export const setIsOtherUser = value => {
  return {
    type: SET_IS_OTHER_USER,
    payload: value
  };
}