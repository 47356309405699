import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { registerUser } from "redux/actions/authActions.js";
import { setAlert } from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const RegisterUserForm = (props) => {
  const classes = useStyles();

  const authState = useSelector((state) => state.auth);

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    role: "",
    organization: "",
    incentive: 0
  });

  const { name, email, role, organization, incentive } = newUser;
  const onChange = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  const [isLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (!props.isAdmin) {
      //Forgive me god for my sins
      newUser.role = "user";
      newUser.organization = authState.user.data.organization;
    }
    if (
      name === "" ||
      email === "" ||
      (props.isAdmin && role === "distributor" && organization === "")
    )
      dispatch(setAlert("Favor de ingresar todos los campos", "error"));
    else dispatch(registerUser(newUser));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Nuevo Usuario
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            helperText="Nombre del Usuario"
            autoComplete="off"
            autoFocus
            value={name}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            helperText="Correo del Usuario"
            autoComplete="off"
            value={email}
            onChange={onChange}
          />
          {props.isAdmin && (
            <FormControl
              required
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel>Tipo de Usuario</InputLabel>
              <Select
                id="role"
                value={role}
                name="role"
                onChange={onChange}
                label="Tipo de Usuario"
              >
                <MenuItem value={"distributor"}>Distribuidor</MenuItem>
                <MenuItem value={"supervisor"}>Validador</MenuItem>
                <MenuItem value={"admin"}>***Administrador***</MenuItem>
              </Select>
              <FormHelperText>Tipo de Usuario</FormHelperText>
            </FormControl>
          )}
          {role === "distributor" && props.isAdmin && (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="organization"
                label="Organización"
                name="organization"
                helperText="Organización del Usuario"
                value={organization}
                onChange={onChange}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="incentive"
                label="Incentivo"
                name="incentive"
                helperText="% Incentivo"
                value={incentive}
                onChange={onChange}
              />
            </>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            margin="normal"
            className={classes.submit}
            value="upload"
          >
            Registrar
          </Button>
          {isLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default RegisterUserForm;
