import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getVerifierMetrics } from "redux/actions/invoiceActions";

import Grid from "@material-ui/core/Grid";
import SalesCard from "components/partials/user/SalesCard";
import InvoiceQueueTable from "components/partials/user/InvoiceQueueTable";
import InvoiceTable from "components/partials/user/InvoiceTable";


const SupervisorPage = ({ history }) => {
  const authState = useSelector((state) => state.auth);
  const invoiceState = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVerifierMetrics(authState.user.data.id));
  }, [authState.user.data, dispatch]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Reportes Procesando"}
              value={invoiceState.verifier?.inProgress}
              isNotCurrency={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Reportes Revisados"}
              color={"success"}
              value={invoiceState.verifier?.reviewed}
              isNotCurrency={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Reportes en Fila"}
              color={"error"}
              value={invoiceState.verifier?.inQueue}
              isNotCurrency={true}
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Invoice form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Invoice Form*/}
          <Grid item xs={12} md={12}>
            <InvoiceQueueTable history={history} />
          </Grid>
          {/*Invoice Table*/}
          <Grid item xs={12} md={12}>
            <InvoiceTable history={history} userId={authState.user.data.id} role={authState.user.data.role} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SupervisorPage;
