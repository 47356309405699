import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Role, hasRole } from "utils/authUtils";
import { editInvoice } from "redux/actions/invoiceActions";

const useStyles = makeStyles((theme) => ({
  document: {
    margin: "20px",
  },
  title: {
    marginTop: "40px",
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));
const InvoiceViewer = ({ invoice }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [amountState, setAmountState] = useState(
    invoice.elements.map(({ amount }) => amount)
  );
  const [approvedState, setApprovedState] = useState(
    invoice.elements.map(({ approved }) => approved)
  );
  const [statusState, setStatusState] = useState(invoice.status);
  const authState = useSelector((state) => state.auth);

  const changeAmount = (e, i) => {
    if (hasRole(authState.user, [Role.SELLER])) {
      const newAmounts = [...amountState];
      newAmounts[i] = e.target.value;
      setAmountState(newAmounts);
    }
  };
  const changeApproved = (e, i) => {
    if (hasRole(authState.user, [Role.VERIFIER])) {
      const newApproved = [...approvedState];
      newApproved[i] = e.target.value;
      setApprovedState(newApproved);
    }
  };

  const hasChanged = () => {
    if (statusState !== invoice.status) {
      return true;
    }
    for (let index = 0; index < invoice.elements.length; index++) {
      const element = invoice.elements[index];
      const amount = element.amount;
      const approved = element.approved;
      if (
        Number(amount) !== Number(amountState[index]) ||
        approved !== approvedState[index]
      ) {
        return true;
      }
    }
    return false;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editInvoice(invoice.id, amountState, approvedState, statusState));
  };
  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={2} alignItems="center" justify="center">
        <Grid container className={classes.document} item xs={12} direction="row">
          <Grid item xs={12} md={12}>
            <h1 className={classes.title}> Título: {invoice.title}</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl
              required
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
              name="status"
              id="status"
            >
              <InputLabel>Estado</InputLabel>
              <Select
                id="status"
                value={statusState}
                name="status"
                disabled={!hasRole(authState.user, [Role.VERIFIER])}
                onChange={(e) =>
                  hasRole(authState.user, [Role.VERIFIER]) &&
                  setStatusState(e.target.value)
                }
                label="Categoría"
              >
                {hasRole(authState.user, [Role.VERIFIER]) ? (
                  [
                    "PENDIENTE",
                    "COMPLETO",
                    "PARCIAL",
                    "INVÁLIDO",
                    "PROCESANDO",
                    "REABIERTO",
                  ].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={statusState}>{statusState}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {invoice.elements.map(({ description, category }, i) => (
            <Grid
              key={`element${i}`}
              className={classes.grid}
              container
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <h2>Descripción: {description}</h2>
              </Grid>
              <Grid item xs={12} md={6}>
                <h3>Categoría: {category.name}</h3>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Reportado"
                    type="number"
                    disabled={!hasRole(authState.user, [Role.SELLER])}
                    value={amountState[i]}
                    onChange={(e) => changeAmount(e, i)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Aprobado"
                    type="number"
                    value={approvedState[i]?approvedState:""}
                    disabled={!hasRole(authState.user, [Role.VERIFIER])}
                    onChange={(e) => changeApproved(e, i)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider
                  classes={{ root: classes.dividerColor }}
                  variant="middle"
                  light
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        {hasRole(authState.user, [Role.SELLER, Role.VERIFIER]) ? (
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
              disabled={!hasChanged()}
            >
              Guardar
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};

export default InvoiceViewer;
