import React,{ useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { loginUser } from "redux/actions/authActions";
import {setAlert} from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  }
}));

const Login = () => {

  const dispatch = useDispatch();
  const classes = useStyles({});
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(true);
  const [isLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if(email === '' || password === ''){
      dispatch(setAlert('Please enter all the fields.', 'error'));
    }else{   
      dispatch(loginUser(email, password, checked));
    }    
  }

  return (
    <>
      <Typography component="h1" variant="h2">
        IGSA
      </Typography>
      <Typography component="h1" variant="h5">
        Programa de Lealtad
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Usuario"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          helperText="Usuario del Sistema de Lealtad"
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Grid container>
          <Grid item xs>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
                value="remember" color="primary" 
              />
            }
            label="Recuérdame"
          />
          </Grid>
          <Grid item style={{paddingTop:"11px"}}>
            <Link to="/forgot">
              {"Olvidé mi contraseña"}
            </Link>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          value="Login"
        >
          Iniciar Sesión
        </Button>
        {isLoading && <CircularProgress />}
      </form>
    </>
  )
}

export default Login