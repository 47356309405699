import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDistributors, getSellers } from "redux/actions/userActions";

import { Grid, InputLabel, FormControl, Select, MenuItem, makeStyles, Button } from "@material-ui/core/";

import SalesCard from "components/partials/user/SalesCard";

import { getReport } from "redux/actions/invoiceActions";
import { parseISO, format } from "date-fns";
import GenericTable from "./GenericTable";

import { Role } from "utils/authUtils"

const columns = [
  { field: "organization", title: "Organización" },
  { field: "title", title: "Título" },
  { field: "seller", title: "Vendedor" },
  { field: "status", title: "Estado" },
  { field: "amount", title: "Monto" },
  { field: "approved", title: "Aprobado" },
  { field: "category", title: "Categoría" },
  { field: "multiplier", title: "Multiplicador" },
  { field: "date", title: "Fecha" },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    height: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DownloadReport = ({ history }) => {
  /*Admin Data Tables*/
  const authState = useSelector((state) => state.auth);
  const invoiceState = useSelector((state) => state.invoice);
  const invoices = invoiceState.invoices;
  const [rows, setRows] = useState([]);

  const userState = useSelector((state) => state.user);
  const distributors = userState.distributors;
  const sellers = userState.sellers;

  const classes = useStyles();
  const [organization, setOrganization] = React.useState('');
  const [distributor, setDistributor] = React.useState('');
  const [user, setUser] = React.useState('');

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /*End Admin Data Tables*/

  const dispatch = useDispatch();

  const fetchUsers = (organization) => {
    dispatch(getSellers(organization));
  }

  const handleOrganization = (event) => {
    setOrganization(event.target.value);
    setUser('');
    if (event.target.value !== "") {
      const dataDist = distributors.find(dist => dist.organization === event.target.value);
      if (dataDist) {
        setDistributor(dataDist._id);
        fetchUsers(event.target.value);
      }

    }
  };

  const fetchReport = () => {
    setRows([]);
    if (organization !== "" && user === "" && distributor) {
      dispatch(getReport(Role.DISTRIBUTOR, distributor));
    }
    if (organization !== "" && user !== "") {
      dispatch(getReport(Role.SELLER, user));
    }
  };

  const displayData = (data) => {
    if (Array.isArray(data) && rows.length === 0) {
      data.forEach((invoice) => {
        invoice.elements.forEach(({ amount, approved, category }) => {
          setRows((state) => [
            ...state,
            {
              id: invoice.id,
              organization: invoice.organization,
              title: invoice.title,
              seller: invoice.seller,
              status: invoice.status,
              amount: `$${amount.toLocaleString("en-US")}`,
              approved: `$${(approved ?? 0).toLocaleString("en-US")}`,
              category: category.name,
              multiplier: category.multiplier,
              date: format(parseISO(invoice.createdAt), "dd/MM/yyyy hh:mm"),
            },
          ]);
        });
      });
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(getReport(authState.user.data.role, authState.user.data.id));

      if (authState.user.data.role === Role.ADMIN) {
        dispatch(getDistributors());
      }

      if (authState.user.data.role === Role.DISTRIBUTOR) {
        dispatch(getSellers(authState.user.data.organization));
      }
      setIsFirstLoad(false);
    }
  }, [dispatch]);

  displayData(invoices);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      { (authState.user.data.role !== Role.SELLER) &&
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
            { (authState.user.data.role === Role.ADMIN) && 
              <Grid item xs={12} md={5}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="organization">Organización</InputLabel>
                  <Select
                    labelId="organization"
                    id="organization"
                    label="Organización"
                    value={organization}
                    onChange={handleOrganization}
                  >
                    <MenuItem value="">
                      <em>-</em>
                    </MenuItem>
                    {Array.isArray(distributors) && distributors.map(item =>
                      <MenuItem value={item.organization} key={item.organization}>
                        <em>{item.organization}</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12} md={5}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="user">Usuario</InputLabel>
                <Select
                  labelId="user"
                  id="user"
                  label="Usuario"
                  value={user}
                  onChange={(event) => setUser(event.target.value)}
                >
                  <MenuItem value="">
                    <em>-</em>
                  </MenuItem>
                  {Array.isArray(sellers) && sellers.map(item =>
                    <MenuItem value={item._id} key={item._id}>
                      <em>{item.name}</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Button variant="contained" color="primary" className={classes.button} onClick={fetchReport}>
                  Ver
              </Button>
              </FormControl>

            </Grid>
          </Grid>
        </Grid>
      }
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={3}>
            <SalesCard
              title={"Total de Ventas"}
              value={
                invoiceState.sales !== null ? invoiceState.sales.TotalSales : 0
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={
                invoiceState.sales !== null
                  ? invoiceState.sales.AcceptedSales
                  : 0
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={
                invoiceState.sales !== null
                  ? invoiceState.sales.RejectedSales
                  : 0
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SalesCard
              title={"Número de Facturas"}
              value={
                invoiceState.invoices !== null
                  ? invoiceState.invoices.length
                  : 0
              }
              isNotCurrency={true}
            />
          </Grid>
        </Grid>
      </Grid>
      {/*Table*/}
      <Grid item xs={12}>
        <GenericTable
          title="Facturas"
          columns={columns}
          data={rows}
        />
      </Grid>
    </Grid>
  );
};

export default DownloadReport;
