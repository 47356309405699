import React,{ useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { recoverPassword } from "redux/actions/authActions";
import {setAlert} from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  }
}));

const ForgotPassword = ({history}) => {

  const dispatch = useDispatch();
  const classes = useStyles({})
  
  const [email, setEmail] = useState('');
  const [isLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    if(email === ''){
      dispatch(setAlert('Por favor ingresa el correo.', 'error'));
    }else{
      dispatch(recoverPassword(email, history))
    }    
  }

  return (
    <>
      <Typography component="h1" variant="h2">
        IGSA
      </Typography>
      <Typography component="h1" variant="h5">
        Olvidé mi contraseña
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Usuario"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          value="Login"
        >
          Enviar contraseña
        </Button>
        <Grid item style={{paddingTop:"11px"}}>
            <Link to="/login">
              {"Regresar al login"}
            </Link>
          </Grid>
        {isLoading && <CircularProgress />}
      </form>
    </>
  )
}

export default ForgotPassword