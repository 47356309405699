import React,{ useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { resetPassword } from "redux/actions/authActions";
import {setAlert} from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Checkbox from "@material-ui/core/Checkbox";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  }
}));

const ResetPassword = ({history}) => {

  const state = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles({})
  
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isLoading] = useState(false);

  const validatePassword = () => {
    if (newPassword === confirmPassword &&
      newPassword.length > 8 &&
      newPassword.match(/[A-Z]+/) &&
      newPassword.match(/[a-z]+/) &&
      newPassword.match(/[0-9]+/) &&
      newPassword.match(/[!¡¿.*+\-?^ ${}()|[\]\\]+/)
      ) {
        return true;
    }
    return false;
  }

  const onSubmit = e => {
    e.preventDefault();
    if(password === ''){
      dispatch(setAlert('Por favor ingresa la contraseña actual.', 'error'));
    } else if (!validatePassword()) {
      dispatch(setAlert('Revisa la contraseña nueva.', 'error'));
    }else{
      dispatch(resetPassword(state.user.data,password, newPassword, history))
    }    
  }

  return (
    <>  
      <Typography component="h1" variant="h5">
        Cambiar mi contraseña
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña Anterior o Temporal"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña Nueva"
          type="password"
          id="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
         <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Confirmar Contraseña"
          type="password"
          id="password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <List>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword.length > 8}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="La contraseña contiene más de 8 caracteres." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword.match(/[A-Z]+/)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="La contraseña contiene al menos una mayúscula." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword.match(/[a-z]+/)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="La contraseña contiene al menos una minúscula." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword.match(/[0-9]+/)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="La contraseña contiene al menos un dígito." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword.match(/[!¡¿.*+\-?^ ${}()|[\]\\]+/)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="La contraseña contiene al menos un símbolo especial."  secondary="! ¡ ¿ . * + \ - ? ^ $ { } ( ) | [ ] \" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={newPassword === confirmPassword}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary="Las contraseñas coinciden" />
          </ListItem>
        </List>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          value="Login"
          disabled={!validatePassword()}
        >
          Cambiar contraseña
        </Button>
        {isLoading && <CircularProgress />}
      </form>
    </>
  )
}

export default ResetPassword