export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "FORGET_SUCCESS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const SET_DRAWERLIST = "SET_DRAWERLIST";
export const SET_SALES = "SET_SALES";
export const RESET_STATE = "RESET_STATE";
export const REMEMBER_ME = "REMEMBER_ME";
export const SET_INVOICES = "SET_INVOICES";
export const SET_DISTRIBUTORS = "SET_DISTRIBUTORS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_INVOICE = "SET_INVOICE";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const SET_SELLERS = "SET_SELLERS";
export const SET_VERIFIERS = "SET_VERIFIERS";
export const SET_VERIFIER_METRICS = "SET_VERIFIER_METRICS";
export const SET_QUEUE = "SET_QUEUE";
export const SET_LEAD = "SET_LEAD";
export const SET_LEADSTATUS = "SET_LEADSTATUS";
export const SET_LOOKUP = "SET_LOOKUP";
export const SET_IS_OTHER_USER = "SET_IS_OTHER_USER";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_TIME = "SET_TIME";