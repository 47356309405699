import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSales } from "redux/actions/invoiceActions";
import { Role } from "utils/authUtils";

import Grid from "@material-ui/core/Grid";
import SalesCard from "components/partials/user/SalesCard";
import InvoiceTable from "components/partials/user/InvoiceTable";
import SellerListTable from "../partials/user/SellerListTable";
import { setDrawerList } from "redux/actions/uiActions";

const WatchDistributorPage = ({ history, match }) => {
  const invoiceState = useSelector((state) => state.invoice);
  const { id, organization } = match.params;
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);
  const isOtherUser = uiState.isOtherUser;
 

  useEffect(() => {
    dispatch(getSales(Role.DISTRIBUTOR, id));
    if (!isOtherUser) {
      dispatch(setDrawerList(id));
    }
  }, [dispatch, id, isOtherUser]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Total de Ventas"}
              value={invoiceState.sales !== null? invoiceState.sales.TotalSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={invoiceState.sales !== null? invoiceState.sales.AcceptedSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={invoiceState.sales !== null? invoiceState.sales.RejectedSales : 0}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* New User Form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <SellerListTable viewOnly={true} history={history} organization={organization} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <InvoiceTable history={history} userId={id} role={Role.DISTRIBUTOR} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WatchDistributorPage;
