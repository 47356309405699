import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core/";
import LeadTable from "components/partials/user/LeadRESTTable";
import { Role } from "utils/authUtils";

const LeadPage = () => {
  const authState = useSelector((state) => state.auth);
  const user = authState.user.data;
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <LeadTable role={user.role} id={user.id} viewOnly={user.role === Role.ADMIN} />
    </Grid>
  );
};

export default LeadPage;
