import {
  SET_DISTRIBUTORS,
  SET_SELLERS,
  SET_VERIFIERS,
  SET_NOTIFICATIONS,
} from "redux/actions/types";

const initialState = {
  distributors: null,
  notifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DISTRIBUTORS:
      return {
        ...state,
        distributors: action.payload,
      };
    case SET_SELLERS:
      return {
        ...state,
        sellers: action.payload,
      };
    case SET_VERIFIERS:
      return {
        ...state,
        verifiers: action.payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
}
