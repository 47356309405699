import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getInvoice } from "redux/actions/invoiceActions";

import { Grid, Paper, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";

import FileViewer from "../partials/user/FileViewer";
import InvoiceViewer from "../partials/user/InvoiceViewer";
import FileSelector from "../partials/user/FileSelector";
import InvoiceConversation from "../partials/user/InvoiceConversation";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    height: '50vh!important',
    width: '100%',
    flexDirection: 'column',
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}));

const InvoicePage = ({ match }) => {
  const [filename, setFilename] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = match.params;
  useEffect(() => {
    dispatch(getInvoice(id, setIsLoading, setFilename));
  }, [id, dispatch]);

  const invoiceState = useSelector((state) => state.invoice);
  const { invoice } = invoiceState;
  return !isLoading ? (
    <Grid container spacing={2} alignItems="stretch" direction="row">
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="stretch" direction="row">
          {/*Invoice Form*/}
          <Grid item xs={12} md={6}>
            <Grid container spacing={3} alignItems="stretch" direction="row">
              <Grid item xs={12}>
                <Paper>
                  <InvoiceViewer invoice={invoice} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <div className={classes.container}>
                    <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                      <Typography variant="h6">
                        Conversación: {invoice?.title}
                      </Typography>
                    </MuiDialogTitle>
                    <InvoiceConversation
                      conversation={invoice.conversation}
                      id={invoice.id}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <FileSelector
                setFilename={setFilename}
                filename={filename}
                documents={invoice.documents}
              />
              <FileViewer filename={filename} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
      <div>
        <h1>Cargando reporte, por favor espere...</h1>
      </div>
    );
};

export default InvoicePage;
