import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_DISTRIBUTORS,
  SET_SELLERS,
  SET_VERIFIERS,
  SET_NOTIFICATIONS,
} from "./types";

export const getDistributors = () => (dispatch) => {
  axios
    .get("/api/user/getDistributors")
    .then((res) => {
      dispatch(setDistributors(res.data.data));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const getVerifiers = () => (dispatch) => {
  axios
    .get("/api/user/getVerifiers")
    .then((res) => {
      dispatch(setVerifiers(res.data.data));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const setVerifiers = (obj) => {
  return {
    type: SET_VERIFIERS,
    payload: obj,
  };
};

export const setDistributors = (obj) => {
  return {
    type: SET_DISTRIBUTORS,
    payload: obj,
  };
};

export const setNotifications = (obj) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: obj
  }
}
export const getSellers = (organization) => (dispatch) => {
  axios
    .get(`/api/user/getSellers/${organization}`)
    .then((res) => {
      dispatch(setSellers(res.data.data));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const updateUser = (resolve, id, name, email, incentive) => (
  dispatch
) => {
  axios
    .put(`api/user/updateUser/${id}`, { id, name, email, incentive })
    .then((res) => {
      //Even though we set the distributors/sellers as gargabe data, this forces the table to re-render and fetch the new data
      dispatch(setDistributors(res.data.data));
      dispatch(setSellers(res.data.data));
      resolve();
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    });
};

export const deleteUser = (resolve, id) => (dispatch) => {
  axios
    .delete(`api/category/${id}`)
    .then((res) => {
      //Even though we set the distributors/sellers as gargabe data, this forces the table to re-render and fetch the new data
      dispatch(setDistributors(res.data.data));
      dispatch(setSellers(res.data.data));
      resolve();
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    });
};

export const setSellers = (obj) => {
  return {
    type: SET_SELLERS,
    payload: obj,
  };
};
