import axios from "utils/axios";

import { setAlert } from "./alertAction";
import { CLEAR_ERRORS, GET_ERRORS, SET_LEAD, SET_LOOKUP, SET_LEADSTATUS } from "./types";

export const getLeads = (role, user) => (dispatch) => {
  axios
    .get(`/api/lead/${role}/${user}`)
    .then(res => {
      dispatch(setLead(res.data.data.leads));
      dispatch(setLeadStatus(res.data.data.leadStatus));
      dispatch(setLookUp(res.data.data.lookUpUsers));
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    })
};

export const updateLead = (resolve, id, name, location, teamSize, owner, status) => dispatch => {
  axios
    .put(`/api/lead/${id}`, { name, location, teamSize, owner, status })
    .then(res => {
      dispatch(setLead(res.data.data));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const deleteLead = (resolve, id) => dispatch => {
  axios
    .delete(`/api/lead/${id}`)
    .then(res => {
      dispatch(setLead(res.data.data));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const createLead = (resolve, name, location, teamSize, owner, status) => dispatch => {
  axios
    .post('/api/lead/', { name, location, teamSize, owner, status})
    .then(res => {
      dispatch(setLead(null));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const setLead = obj => {
  return {
    type: SET_LEAD,
    payload: obj
  }
};

export const setLeadStatus = obj => {
  return {
    type: SET_LEADSTATUS,
    payload: obj
  }
};

export const setLookUp = obj => {
  return {
    type: SET_LOOKUP,
    payload: obj
  }
};