import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { registerInvoice } from "redux/actions/invoiceActions";
import { getCategories } from "redux/actions/categoryActions";
import { setAlert } from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const InvoiceForm = (props) => {
  const classes = useStyles();

  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [amount, setAmount] = useState([""]);
  const [description, setDescription] = useState([""]);
  const [category, setCategory] = useState([""]);
  const [isLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const categoryState = useSelector((state) => state.category);
  const categories = categoryState.categories;

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (files == null) {
      dispatch(setAlert("Por favor adjunta mínimo un archivo", "error"));
      return;
    }

    for (const key of Object.keys(files)) {
      formData.append("invoices", files[key]);
    }

    if (!title.trim()) {
      dispatch(setAlert("Por favor ingresa el título del reporte", "error"));
      return;
    }
    formData.append("title", title);

    if (category.length === 0) {
      dispatch(
        setAlert("Mínimo debe existir un elemento en el reporte", "error")
      );
      return;
    }
    let send = true;
    category.forEach((value) => {
      if (!value) {
        send = false;
      }
      formData.append("category", value);
    });
    if (!send) {
      dispatch(
        setAlert("Por favor elige la categoría de todos los elementos"),
        "error"
      );
      return;
    }
    amount.forEach((value) => {
      if (!value) {
        send = false;
      }
      formData.append("amount", value);
    });
    if (!send) {
      dispatch(
        setAlert("Por favor ingresa el monto de todos los elementos", "error")
      );
      return;
    }
    description.forEach((value) => {
      if (!value) {
        send = false;
      }
      formData.append("description", value);
    });
    if (!send) {
      dispatch(
        setAlert(
          "Por favor ingresa la descripción de todos los elementos",
          "error"
        )
      );
      return;
    }
    formData.append("initialComment", comment);
    dispatch(registerInvoice(formData));
  };

  const changeCategory = (e, i) => {
    const newCategory = [...category];
    newCategory[i] = e.target.value;
    setCategory(newCategory);
  };
  const changeAmount = (e, i) => {
    const newAmount = [...amount];
    newAmount[i] = e.target.value;
    setAmount(newAmount);
  };

  const changeDescription = (e, i) => {
    const newDescription = [...description];
    newDescription[i] = e.target.value;
    setDescription(newDescription);
  };

  const removeItem = (i) => {
    const newCategory = [...category];
    const newAmount = [...amount];
    const newDescription = [...description];
    newCategory.splice(i, 1);
    newAmount.splice(i, 1);
    newDescription.splice(i, 1);
    setAmount(newAmount);
    setCategory(newCategory);
    setDescription(newDescription);
  };
  const addItem = () => {
    setAmount([...amount, ""]);
    setCategory([...category, ""]);
    setDescription([...description, ""]);
  };

  const onFileChange = (e) => {
    setFiles(e.target.files);
  };

  useEffect(() => {
    categories == null &&
      dispatch(getCategories());
  }, [dispatch, categories]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Título"
            name="title"
            helperText="Nombre del proyecto"
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {amount.map((value, i) => {
            return (
              <Grid
                key={`element${i}`}
                className={classes.grid}
                container
                spacing={2}
                alignItems="center"
                justify="center"
              >
                <Grid item xs={8} md={10}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name={`description${i}`}
                    label="Descripción"
                    type="text"
                    id={`description${i}`}
                    helperText="Escribir la descripción del elemento"
                    value={description[i]}
                    onChange={(e) => changeDescription(e, i)}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <Button
                    type="button"
                    color="primary"
                    margin="normal"
                    variant="outlined"
                    onClick={() => removeItem(i)}
                  >
                    -
                  </Button>
                </Grid>
                <Grid className={classes.grid} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Monto"
                      type="number"
                      helperText="Escribir el monto de lo vendido en USD"
                      value={value}
                      onChange={(e) => changeAmount(e, i)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      required
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      className={classes.formControl}
                      name={`amount${i}`}
                      id={`amount${i}`}
                    >
                      <InputLabel>Categoría</InputLabel>
                      <Select
                        id={`category${i}`}
                        value={category[i]}
                        name={`category${i}`}
                        onChange={(e) => changeCategory(e, i)}
                        label="Categoría"
                      >
                        {
                          categories && categories.map((category) => {
                            return <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                          })
                        }
                      </Select>
                      <FormHelperText>Producto</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider
                    classes={{ root: classes.dividerColor }}
                    variant="middle"
                    light
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid className={classes.grid} container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="button"
                color="secondary"
                margin="normal"
                variant="contained"
                fullWidth={true}
                onClick={addItem}
              >
                +
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className={classes.grid}
            alignItems={"flex-end"}
            direction={"row"}
          >
            <Grid item xs={12}>
              <input
                accept="pdf/*"
                className={classes.input}
                onChange={onFileChange}
                id="file-button"
                multiple
                type="file"
              />
              <label htmlFor="file-button">
                <Button
                  variant="outlined"
                  className={classes.fileButton}
                  onChange={(e) => setFiles(e.target.files)}
                  color="primary"
                  component="span"
                >
                  Subir archivo
                </Button>
              </label>
              <span className={classes.fileLabel}>
                {files == null
                  ? "Máximo 2MB"
                  : Object.keys(files)
                      .map((key) => files[key].name)
                      .join(", ")}
              </span>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="comment"
            label="Comentario inicial"
            name="comment"
            helperText="Consideraciones importantes"
            autoFocus
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            margin="normal"
            className={classes.submit}
            value="upload"
          >
            Subir
          </Button>
          {isLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default InvoiceForm;
