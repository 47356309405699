import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getVerifiers, updateUser, deleteUser } from "redux/actions/userActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const DistributorListTable = () => {

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);
  const verifiers = userState.verifiers;

  const rowAction = [
    {
      icon: 'visibility',
      tooltip: 'Ver usuario',
      onClick: (event, rowData) => alert("Le picaste a: " + rowData.name)
    }
  ];

  const columns = [
    {
      title: 'id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Ventas Totales',
      field: 'amount',
      type: 'currency',
      editable: 'never'
    },
    {
      title: 'Ventas Reconocidas',
      field: 'approved',
      type: 'currency',
      editable: 'never',
    },
    {
      title: 'Total de reportes',
      field: 'count',
      type: 'numeric',
      editable: 'never',
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(newData.email === ""){
      dispatch(setAlert("El email es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(!newData.incentive || newData.incentive < 0 || newData.incentive > 100){
      dispatch(setAlert("El incentivo tiene que ser mayor que 0 y menor que 100", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateUser(resolve, oldData._id, newData.name, newData.email, newData.incentive));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteUser(resolve, oldData._id));
  }

  useEffect(() => {
    verifiers == null &&
      dispatch(getVerifiers());
  }, [dispatch, verifiers]);

  return (
    <RestfulTable
      title={"Lista de Validadores"}
      data={verifiers?verifiers:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      rowAction={rowAction} />
  );
}

export default DistributorListTable;