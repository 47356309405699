import React from "react";

import MaterialTable from "material-table";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";

const tableStyle = makeStyles((theme) => ({
  paper: {
    whiteSpace: 'nowrap',
    width: "100%"
  }
}));

const GenericTable = (props) => {
  const classes = tableStyle({});

  return (
    <MaterialTable
      title={props.title}
      data={props.data? props.data : []}
      columns={props.columns ? props.columns : []}
      parentChildData={ props.expandable ? (row, rows) => rows.find(a => a.id === row.parentId) : null }
      actions={ props.rowAction ? props.rowAction : null }
      options={{ actionsColumnIndex: -1, exportButton: true }}
      components={{
        Container: props => <Paper {...props} className={classes.paper} />,
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'entradas',
          firstTooltip: 'Primera página',
          lastTooltip: 'Última página',
          nextTooltip: 'Página siguiente',
          previousTooltip: 'Página previa'
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar...',
          exportTitle: 'Exportar',
          exportCSVName: 'Exportar como CSV',
          exportPDFName: 'Exportar como PDF',
          exportAriaLabel: 'Exportar',
          exportButton: 'Exportar'
        },
        header: {
          actions: 'Acciones'
        },
        body: {
          emptyDataSourceMessage: 'No hay datos para mostrar :(',
          filterRow: {
            filterTooltip: 'Filtrar'
          }
        },
      }}
    />
  );
}

export default GenericTable;