import {
  SET_SALES,
  SET_INVOICES,
  SET_INVOICE,
  SET_VERIFIER_METRICS,
  SET_QUEUE,
} from "redux/actions/types";

const initialState = {
  invoices: null,
  sales: [],
  invoice: null,
  verifier: [],
  queue: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SALES:
      return {
        ...state,
        sales: action.payload,
      };
    case SET_VERIFIER_METRICS: 
      return {
        ...state,
        verifier: action.payload,
      }
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    case SET_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    default:
      return state;
  }
}
