import axios from "axios";
import { store } from "redux/store";

const axiosInstance = axios.create({
  baseURL: "https://api.mota.technology",
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  config.params = config.params || {};
  config.params["month"] = state.time.month;
  config.params["year"] = state.time.year;
  return config;
});


export default axiosInstance;
