import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSales } from "redux/actions/invoiceActions";

import Grid from "@material-ui/core/Grid";
import SalesCard from "components/partials/user/SalesCard";
import RegisterUserForm from "components/partials/user/RegisterUserForm";
import InvoiceTable from "components/partials/user/InvoiceTable";
import SellerListTable from "../partials/user/SellerListTable";

const DistributorPage = ({ history }) => {
  const authState = useSelector((state) => state.auth);
  const invoiceState = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSales(authState.user.data.role, authState.user.data.id));
  }, [dispatch, authState.user.data]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Total de Ventas"}
              value={invoiceState.sales !== null? invoiceState.sales.TotalSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={invoiceState.sales !== null? invoiceState.sales.AcceptedSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={invoiceState.sales !== null? invoiceState.sales.RejectedSales : 0}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* New User Form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Distributor Register*/}
          <Grid item xs={12} md={5}>
            <RegisterUserForm isAdmin={false} />
          </Grid>
          <Grid item xs={12} md={7}>
            <SellerListTable history={history} organization={authState.user.data.organization} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <InvoiceTable history={history} userId={authState.user.data.id} role={authState.user.data.role} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DistributorPage;
