import React from "react";
import {
  Grid,
  Checkbox,
  ListItemIcon,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";

const FileSelector = ({ documents, setFilename, filename }) => {
  return (
    <Grid item xs={12} md={12}>
      <List>
        {documents?.map((value) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem key={value} button onClick={() => setFilename(value)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={filename === value}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default FileSelector;
