import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { REMOVE_ALERT } from "redux/actions/types";

const Alerts = () => {
  const alertsList = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} action={
      <React.Fragment>
        <IconButton size="small" aria-label="close" color="inherit" onClick={(e, r) => handleClose(e,r,props.alertId)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    } />;
  }

  const handleClose = (event, reason, id) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: REMOVE_ALERT, payload: id });
  };
  if (alertsList.length == 0) {
    return <></>;
  }
  const alert = alertsList[alertsList.length - 1];

  return (
    <Snackbar
      key={alert.id}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={true}
    >
      <Alert severity={alert.type} alertId={alert.id}>
        {alert.message === undefined ? "Error Interno..." : alert.message}
      </Alert>
    </Snackbar>
  );
};

export default Alerts;
