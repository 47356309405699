import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  getInvoiceQueue,
  subscribeToInvoice,
  reviewInvoice,
  clearInvoice,
} from "redux/actions/invoiceActions";

import GenericTable from "./GenericTable";

const columns = [
  { field: "organization", title: "Organización" },
  { field: "name", title: "Vendedor" },
  { field: "title", title: "Título" },
  { field: "status", title: "Estado" },
  { field: "amount", title: "Monto" },
  { field: "approved", title: "Aprobado" },
  { field: "date", title: "Fecha" },
];

const InvoiceQueueTable = ({ history }) => {
  const dispatch = useDispatch();

  const invoiceState = useSelector((state) => state.invoice);
  const queue = invoiceState.queue;

  const [rows, setRows] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const actions = [
    {
      icon: "add_alert",
      tooltip: "Subscribirse",
      onClick: (event, rowData) => dispatch(subscribeToInvoice(rowData.id, history)),
    },
    {
      icon: "rate_review",
      tooltip: "Revisar",
      onClick: (event, rowData) => dispatch(reviewInvoice(rowData.id, history)),
    },
  ];

  const displayData = (data) => {
    if (Array.isArray(data) && rows.length === 0) {
      //TODO optimized this
      data.forEach((invoice) => {
        let amount = 0;
        let approved = 0;
        invoice.elements.forEach(
          ({ amount: elementAmount, approved: elementApproved }) => {
            if (elementApproved != null) {
              approved += elementApproved;
            }
            amount += elementAmount;
          }
        );

        setRows((state) => [
          ...state,
          {            
            id: invoice.id,
            organization: invoice.organization,
            name: invoice.name,
            title: invoice.title,
            status: invoice.status,
            amount: `$${amount.toLocaleString("en-US")}`,
            approved: `$${approved.toLocaleString("en-US")}`,
            date: format(parseISO(invoice.createdAt), 'dd/MM/yyyy hh:mm'),
          },
        ]);
      });
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(getInvoiceQueue());
      setIsFirstLoad(false);
    }
    return () =>{
      dispatch(clearInvoice());
    }
  }, [isFirstLoad, setIsFirstLoad, dispatch]);
  displayData(queue);

  return (
    <GenericTable
      title="Reportes pendientes"
      columns={columns}
      data={rows}
      rowAction={actions}
    />
  );
};

export default InvoiceQueueTable;
