import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "redux/actions/invoiceActions";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, IconButton } from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  bubbleContainer: {
    width: "100%",
    display: "flex",
  },
  bubble: {
    border: "0.5px solid black",
    borderRadius: "10px",
    margin: "5px",
    padding: "10px",
    display: "inline-block",
    minWidth: "100px"
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  right: {
    justifyContent: "flex-end !important",
  },
  left: {
    justifyContent: "flex-start !important",
  },
  chat: {
  },
  username: {
    fontSize: "0.5em",
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));
const InvoiceConversation = ({ conversation, id }) => {
  const authState = useSelector((state) => state.auth);
  const name = authState.user.data.name;
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null)

  const chatBubbles = conversation?.map((msg, i) => {
    const isUserMessage = msg.user === name;
    const direction = isUserMessage ? classes.right : classes.left;
    const color = isUserMessage ? classes.primary : classes.secondary;
    return (
      <div className={`${classes.bubbleContainer} ${direction}`} key={i}>
        <div key={i++} className={`${classes.bubble} ${color}`}>
          <div className={classes.button}>
            {msg.message} <br />
            <span className={classes.username}>{msg.user}</span>
          </div>
        </div>
      </div>
    );
  });

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(scrollToBottom, [conversation]);

  const send = () => {
    if (message !== "") {
      dispatch(sendMessage(message, id));
      setMessage("");
    }
  };

  const sendWithKey = (event) => {
    if(event.key === 'Enter'){
      send();
    }
  }
  return (
    <>
      <MuiDialogContent dividers className={classes.dialogContent}>
        {conversation != null ?
          <div className={classes.container}>{chatBubbles}</div>
          :
          <Typography gutterBottom>
            Cargando...
          </Typography>
        }
      <div ref={messagesEndRef} />
      </MuiDialogContent>
      <MuiDialogActions className={classes.dialogActions}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="center"
          className={classes.chat}
        >
          <Grid item xs={8} md={11}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name={`message`}
              label="Mensaje"
              type="text"
              id={`message`}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={sendWithKey}
            />
          </Grid>
          <Grid item xs={4} md={1}>
            <IconButton aria-label="send" onClick={send}>
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogActions>
    </>
  );
};

export default InvoiceConversation;
