import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSales } from "redux/actions/invoiceActions";
import { Role } from "utils/authUtils";

import Grid from "@material-ui/core/Grid";
import SalesCard from "components/partials/user/SalesCard";
import InvoiceTable from "components/partials/user/InvoiceTable";
import { setDrawerList } from "redux/actions/uiActions";

const WatchUserPage = ({ history, match }) => {
  const invoiceState = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const { id } = match.params;
  const uiState = useSelector((state) => state.ui);
  const isOtherUser = uiState.isOtherUser;

  useEffect(() => {
    dispatch(getSales(Role.SELLER, id));
    if (!isOtherUser) {
      dispatch(setDrawerList(id));
    }
  }, [id, dispatch, isOtherUser]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Total de Ventas"}
              value={invoiceState.sales !== null? invoiceState.sales.TotalSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={invoiceState.sales !== null? invoiceState.sales.AcceptedSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={invoiceState.sales !== null? invoiceState.sales.RejectedSales : 0}
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Invoice form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Invoice Table*/}
          <Grid item xs={12} md={12}>
            <InvoiceTable history={history} userId={id} role={Role.SELLER} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WatchUserPage;