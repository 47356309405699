import { SET_CATEGORY } from "redux/actions/types";

const initialState = {
  categories: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        ...state,
        categories: action.payload
      }
    default:
      return state;
  }
}