import axios from "utils/axios";
import setAuthToken from "utils/setAuthToken";
import {setAlert} from "./alertAction";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, CLEAR_ERRORS, LOGIN_SUCCESS, PASSWORD_RESET, RESET_STATE, REMEMBER_ME, PASSWORD_CHANGED } from "./types";

// Register User
export const registerUser = (user) => dispatch => {
  axios
    .post("/api/auth/register", user)
    .then(res => {
      dispatch(setAlert("Usuario creado existosamente", 'success'));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data
      })
    );
};

// Login - get user token
export const loginUser = (email, password, rememberMe) => dispatch => {
  axios
    .post("/api/auth/login", {email, password})
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      //localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Set current token
      let type = LOGIN_SUCCESS;
      if (res.status === 202) {
        type = PASSWORD_RESET;
      }
      dispatch({type: REMEMBER_ME, payload: rememberMe})
      dispatch({type, payload:token});
      dispatch(setUserLoading());
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({type: CLEAR_ERRORS});
      dispatch(setCurrentUser({}));
      dispatch({type: GET_ERRORS,payload: err?.response?.data});
    });
};

// User loading
export const setUserLoading = () => dispatch => {
  dispatch({type: USER_LOADING});
  axios
    .get("/api/auth/me")
    .then(res => {
      const userData = res.data;
      dispatch(setCurrentUser(userData));
    })
    .catch(err => {
      dispatch(setCurrentUser({}));
    });
};

// Recovers password
export const recoverPassword = (email, history) => dispatch => {
  axios
    .post("/api/auth/forget", {email})
    .then(res => {
      dispatch(setAlert("Contraseña enviada", 'success'));
      dispatch(setCurrentUser({}));
      history.push("/login");
    })
    .catch(err => {
      dispatch(setAlert(err.response.data.message, 'error'));
      dispatch({type: CLEAR_ERRORS});
      dispatch({type: GET_ERRORS,payload: err?.response?.data});
    });
};


// Reset password
export const resetPassword = (user, password, newPassword, history) => dispatch => {
  axios
    .post("/api/auth/reset", {id: user.id, password, newPassword})
    .then(res => {
      dispatch({type: PASSWORD_CHANGED })
      dispatch(setAlert("Contraseña cambiada existosamente", 'success'));
      history.push("/dashboard");
    })
    .catch(err => {
      dispatch(setAlert(err.response.data.message, 'error'));
      dispatch({type: CLEAR_ERRORS});
      dispatch({type: GET_ERRORS,payload: err.response.data});
    });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  //dispatch(setCurrentUser({}));
  dispatch({type: RESET_STATE});
};