import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setDrawerList } from "redux/actions/uiActions";

import { NavLink } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const isEmpty = require("is-empty");

const useStyles = makeStyles((theme) => ({
  listItemText: {
    padding: theme.spacing(1),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  divider: {
    padding: theme.spacing(1),
  },
  active: {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
  },
}));

const DrawerList = ({ history }) => {
  const uiState = useSelector((state) => state.ui);
  const isOtherUser = uiState.isOtherUser;
  const drawerList = uiState.drawerList;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(setDrawerList());
    },
    [dispatch],
    setDrawerList
  );

  return (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.divider} />
      <List>
        {!isEmpty(drawerList) &&
          drawerList.map((item) => (
            <ListItem
              button
              key={item.link}
              component={NavLink}
              exact
              to={item.link}
              activeClassName={classes.active}
            >
              {/* item.icon to get icon */}
              <ListItemText
                primary={item.label}
                className={classes.listItemText}
              />
            </ListItem>
          ))}
        {isOtherUser && (
          <ListItem
            button
            key="returnToDashboard"
            onClick={() => {
              dispatch(setDrawerList());
              history.push("/dashboard");
            }}
          >
            <ListItemText
              primary="Regresar a Mi Dashboard"
              className={classes.listItemText}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default DrawerList;
