import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Role } from "utils/authUtils";
import { Grid } from "@material-ui/core/";
import LeadTable from "components/partials/user/LeadRESTTable";
import { setDrawerList } from "redux/actions/uiActions";

const WatchLeadPage = ({ history, match }) => {
  const authState = useSelector((state) => state.auth);
  const { id, organization, role } = match.params;
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);
  const isOtherUser = uiState.isOtherUser;
 
  useEffect(() => {
    if (!isOtherUser) {
      dispatch(setDrawerList(id));
    }
  }, [dispatch, id, isOtherUser]);
  if (
    (authState.user.data.role === Role.DISTRIBUTOR &&
      role === Role.DISTRIBUTOR &&
      id !== authState.user.data.id) ||
    (authState.user.data.role === Role.DISTRIBUTOR &&
      organization !== authState.user.data.organization)
  ) {
    return <h1>No autorizado</h1>;
  }

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <LeadTable role={role} id={id} viewOnly={true} />
    </Grid>
  );
};

export default WatchLeadPage;
