import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Grid } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  document: {
    margin: 20,
  },
});
const FileViewer = ({ filename }) => {
  const authState = useSelector((state) => state.auth);
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Grid item xs={12} md={12} className={classes.document}>
      {filename != null && filename.trim() !== "" ? (
        <>
          <Document
            file={{
              url: `https://api.mota.technology/api/invoice/download/${filename}`,
              httpHeaders: { Authorization: `Bearer ${authState.token}` },
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="Cargando el documento, por favor espere..."
            error="Archivo no encontrado"
          >
            <Page
              pageNumber={pageNumber}
              loading="Cargando página, por favor espere..."
            />
          </Document>
          <p>
            <IconButton
              aria-label="before"
              disabled={pageNumber === 1}
              onClick={() => {
                setPageNumber(pageNumber - 1);
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>{" "}
            Página {pageNumber} de {numPages}
            <IconButton
              aria-label="next"
              disabled={pageNumber === numPages}
              onClick={() => {
                setPageNumber(pageNumber + 1);
              }}
            >
              <NavigateNextIcon />
            </IconButton>
          </p>
        </>
      ) : (
        <p> Buscando el archivo...</p>
      )}
    </Grid>
  );
};

export default FileViewer;
