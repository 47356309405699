import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDistributors, updateUser, deleteUser } from "redux/actions/userActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";
import { setDrawerList } from "redux/actions/uiActions";

import RestfulTable from "./GenericRestfulTable";

const DistributorListTable = ({ history }) => {

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);
  const distributors = userState.distributors;

  const rowAction = [
    {
      icon: 'visibility',
      tooltip: 'Ver usuario',
      onClick: (event, rowData) => {
        dispatch(setDrawerList(rowData._id));
        history.push(`/dashboard/distributor/${rowData.organization}/${rowData._id}`);
      }
    }
  ];

  const columns = [
    {
      title: 'id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Organización',
      field: 'organization',
      editable: 'never'
    },
    {
      title: '% Incentivo',
      field: 'incentive',
      type: 'numeric',
      render: row => <span>{ row["incentive"] ? `${row["incentive"]}%` : '0%' }</span>
    },
    {
      title: 'Ventas Totales',
      field: 'amount',
      type: 'currency',
      editable: 'never'
    },
    {
      title: 'Ventas Reconocidas',
      field: 'approved',
      type: 'currency',
      editable: 'never',
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(newData.email === ""){
      dispatch(setAlert("El email es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(!newData.incentive || newData.incentive < 0 || newData.incentive > 100){
      dispatch(setAlert("El incentivo tiene que ser mayor que 0 y menor que 100", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateUser(resolve, oldData._id, newData.name, newData.email, newData.incentive));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteUser(resolve, oldData._id));
  }

  useEffect(() => {
    distributors == null &&
      dispatch(getDistributors());
  }, [dispatch, distributors]);

  return (
    <RestfulTable
      title={"Lista de Distribuidores"}
      data={distributors?distributors:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      rowAction={rowAction} />
  );
}

export default DistributorListTable;