import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  SET_SALES,
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_INVOICES,
  SET_INVOICE,
  SET_VERIFIER_METRICS,
  SET_QUEUE,
} from "./types";

export const clearInvoice = () => (dispatch) => {
  dispatch(setVerifierMetrics(null));
  dispatch(setSales(null));
  dispatch(setInvoice(null));
  dispatch(setInvoices(null));
}

export const getVerifierMetrics = (user) => (dispatch) => {
  axios
    // Should we receive the role by the component or should we access it by the store singleton?
    .get(`/api/invoice/metrics/${user}`)
    .then((res) => {
      dispatch(setVerifierMetrics(res.data.data));
    })
    .catch((err) => {
      dispatch(setVerifierMetrics({}));
    });
};

export const setVerifierMetrics = (obj) => {
  return {
    type: SET_VERIFIER_METRICS,
    payload: obj,
  };
};

export const getSales = (role, user) => (dispatch) => {
  axios
    // Should we receive the role by the component or should we access it by the store singleton?
    .get(`/api/invoice/sales/${role}/${user}`)
    .then((res) => {
      dispatch(setSales(res.data.data));
    })
    .catch((err) => {
      dispatch(setSales({}));
    });
};

export const getReport = (role, user) => (dispatch) => {
  axios
    .get(`/api/invoice/downloadReport/${role}/${user}`)
    .then((res) => {
      dispatch(setSales(res.data.sales));
      dispatch(setInvoices(res.data.invoices));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const setSales = (obj) => {
  return {
    type: SET_SALES,
    payload: obj,
  };
};

export const registerInvoice = (formData) => (dispatch) => {
  axios
    .post("/api/invoice/register", formData, {})
    .then((res) => {
      dispatch(setAlert("Factura registrada", "success"));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const subscribeToInvoice = (id, history) => (dispatch) => {
  axios
    .post(`/api/invoice/subscribe/${id}`)
    .then((res) => {
      history.push(`/invoice/${id}`);
    })
    .catch((err) => {
      dispatch(setInvoice(null));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
};
export const reviewInvoice = (id, history) => (dispatch) => {
  axios
    .post(`/api/invoice/queue/${id}`)
    .then((res) => {
      dispatch(getInvoiceQueue());
      history.push(`/invoice/${id}`);
    })
    .catch((err) => {
      dispatch(setInvoice(null));
      dispatch(setAlert(err?.response?.data.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const editInvoice = (id, amount, approved, status) => (dispatch) => {
  axios
    .post(`/api/invoice/edit/${id}`, { amount, approved, status })
    .then((res) => {
      dispatch(setAlert("Se editó correctamente el reporte", "success"));
      dispatch(setInvoice(res.data.data));
      dispatch(setInvoices(null));
      dispatch(setQueue(null));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const getInvoices = (role, user) => (dispatch) => {
  axios
    .get(`/api/invoice/information/${role}/${user}`)
    .then((res) => {
      dispatch(setInvoices(res.data.data));
    })
    .catch((err) => {
      dispatch(setInvoices(null));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const getInvoiceQueue = () => (dispatch) => {
  axios
    .get(`/api/invoice/queue`)
    .then((res) => {
      dispatch(setQueue(res.data.data));
    })
    .catch((err) => {
      dispatch(setQueue(null));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const getInvoice = (id, setIsLoading, setFilename) => (dispatch) => {
  axios
    .get(`/api/invoice/view/${id}`)
    .then((res) => {
      dispatch(setInvoice(res.data.data));
      setIsLoading(false);
      setFilename(res.data.data.documents[0]);
    })
    .catch((err) => {
      dispatch(setInvoice(null));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const sendMessage = (message, id) => (dispatch) => {
  axios
    .post("/api/invoice/send", { message, id })
    .then((res) => {
      dispatch(setInvoice(res.data.data));
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    });
};

export const setInvoice = (obj) => {
  return {
    type: SET_INVOICE,
    payload: obj,
  };
};
export const setQueue = (obj) => {
  return {
    type: SET_QUEUE,
    payload: obj,
  };
};
export const setInvoices = (obj) => {
  return {
    type: SET_INVOICES,
    payload: obj,
  };
};
