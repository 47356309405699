import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSellers, updateUser, deleteUser } from "redux/actions/userActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";
import { setDrawerList } from "redux/actions/uiActions";

import RestfulTable from "./GenericRestfulTable";

const SellerListTable = ({ organization, viewOnly, history }) => {
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);
  const sellers = userState.sellers;
  const [isFirstLoad, setIsFirstLoad] = useState(true);


  const rowAction = [
    {
      icon: "visibility",
      tooltip: "Ver usuario",
      onClick: (event, rowData) => {
        dispatch(setDrawerList(rowData._id));
        history.push(`/dashboard/seller/${rowData.organization}/${rowData._id}`);
      },
    },
  ];

  const columns = [
    {
      title: 'id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Organización',
      field: 'organization',
      editable: 'never'
    },
    {
      title: 'Ventas Totales',
      field: 'amount',
      type: 'currency',
      editable: 'never'
    },
    {
      title: 'Ventas Reconocidas',
      field: 'approved',
      type: 'currency',
      editable: 'never'
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if (viewOnly) {
      resolve();
      return;
    }

    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(newData.email === ""){
      dispatch(setAlert("El email es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateUser(resolve, oldData._id, newData.name, newData.email));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    if (viewOnly) {
      resolve();
      return;
    }

    dispatch(deleteUser(resolve, oldData._id));
  }

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(getSellers(organization));
      setIsFirstLoad(false);
    } 
  }, [dispatch, isFirstLoad, setIsFirstLoad, organization]);

  return (
    <RestfulTable
      title={"Lista de Usuarios"}
      data={sellers ? sellers : []}
      dataColumns={columns}
      handleRowUpdate={viewOnly ? null : handleRowUpdate}
      handleRowDelete={viewOnly ? null : handleRowDelete}
      rowAction={rowAction}
    />
  );
};

export default SellerListTable;
