import { SET_TIME } from "redux/actions/types";
const today = new Date();
const initialState = {
  month: today.getMonth(),
  year: today.getFullYear(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TIME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
