import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  getInvoices,
  getInvoice,
  subscribeToInvoice,
  clearInvoice,
} from "redux/actions/invoiceActions";

import GenericTable from "./GenericTable";
import InvoiceConversation from "./InvoiceConversation";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { format, parseISO } from "date-fns";

const columns = [
  { field: "organization", title: "Organización" },
  { field: "title", title: "Título" },
  { field: "status", title: "Estado" },
  { field: "amount", title: "Monto" },
  { field: "approved", title: "Aprobado" },
  { field: "date", title: "Fecha" },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "fixed",
    bottom: "0",
    left: "0",
    height: "80vh",
    margin: "auto",
    outline: "none",
    // x = 100 * ((100-w)/2) / w
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "95vh",
      transform: "translate(5.5555%, 0)",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      transform: "translate(21.4285%, 0)",
    },
    backgroundColor: "white",
  },
  appBar: {
    position: "relative",
  },
  container: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  center: {
    alignItems: "center",
    textAlign: "center",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvoiceTable = ({ history, userId, role }) => {
  const dispatch = useDispatch();

  const invoiceState = useSelector((state) => state.invoice);
  const invoices = invoiceState.invoices;

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const invoice = invoiceState.invoice;
  const [showModal, setShowModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const actions = [
    {
      icon: "forum",
      tooltip: "Conversación",
      onClick: (event, rowData) => {
        setShowModal(true);
        dispatch(
          getInvoice(
            rowData.id,
            (bool) => {},
            (str) => {}
          )
        );
      },
    },
    {
      icon: "visibility",
      tooltip: "Ver",
      onClick: (event, rowData) => history.push(`/invoice/${rowData.id}`),
    },
    {
      icon: "add_alert",
      tooltip: "Subscribirse",
      onClick: (event, rowData) =>
        dispatch(subscribeToInvoice(rowData.id, history)),
    },
  ];

  const displayData = (data) => {
    if (Array.isArray(data) && rows.length === 0) {
      //TODO optimized this
      data.forEach((invoice) => {
        let amount = 0;
        let approved = 0;
        invoice.elements.forEach(
          ({ amount: elementAmount, approved: elementApproved }) => {
            if (elementApproved != null) {
              approved += elementApproved;
            }
            amount += elementAmount;
          }
        );

        setRows((state) => [
          ...state,
          {
            id: invoice.id,
            organization: invoice.organization,
            title: invoice.title,
            status: invoice.status,
            amount: `$${amount.toLocaleString("en-US")}`,
            approved: `$${approved.toLocaleString("en-US")}`,
            date: format(parseISO(invoice.createdAt), "dd/MM/yyyy hh:mm"),
          },
        ]);
      });
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(getInvoices(role, userId));
      setIsFirstLoad(false);
    }
    return () => {
      
      dispatch(clearInvoice());
    };
  }, [isFirstLoad, setIsFirstLoad, userId, role, dispatch]);
  displayData(invoices);
  return (
    <>
      <GenericTable
        title="Facturas"
        columns={columns}
        data={rows}
        rowAction={actions}
      />
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <div className={classes.paper}>
          <div className={classes.container}>
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
              <Typography variant="h6">
                Conversación: {invoice?.title}
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setShowModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <InvoiceConversation
              conversation={invoice?.conversation}
              id={invoice?.id}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InvoiceTable;
