import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSales } from "redux/actions/invoiceActions";

import { Grid, Tabs, Tab, Paper } from "@material-ui/core/";
import SalesCard from "components/partials/user/SalesCard";
import RegisterUserForm from "components/partials/user/RegisterUserForm";
import DistributorTable from "components/partials/user/DistributorListTable";
import VerifierTable from "components/partials/user/VerifierListTable";
import CategoryTable from "components/partials/user/CategoryTable";
import InvoiceTable from "components/partials/user/InvoiceTable";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
const AdminPage = ({ history }) => {
  const authState = useSelector((state) => state.auth);
  const invoiceState = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    dispatch(getSales(authState.user.data.role, authState.user.data.id));
  }, [dispatch, authState.user.data]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Total de Ventas"}
              value={invoiceState.sales !== null? invoiceState.sales.TotalSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={invoiceState.sales !== null? invoiceState.sales.AcceptedSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={invoiceState.sales !== null? invoiceState.sales.RejectedSales : 0}
            />
          </Grid>
        </Grid>
      </Grid>
      

      {/* New User Form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Distributor Register*/}
          <Grid item xs={12} md={6}>
            <RegisterUserForm isAdmin={true} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CategoryTable />
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, v) => setTab(v)}
                aria-label="Usuarios"
              >
                <Tab label="Distribuidores" />
                <Tab label="Validadores" />
              </Tabs>

              <TabPanel value={tab} index={0}>
                <DistributorTable history={history} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <VerifierTable history={history} />
              </TabPanel>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <InvoiceTable
              history={history}
              userId={authState.user.data.id}
              role={authState.user.data.role}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminPage;
