import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Switch, useLocation } from "react-router-dom";
import io from "socket.io-client";

import { Role, hasRole } from "utils/authUtils";
import PrivateRoute from "components/private-route/PrivateRoute";
import { setUserLoading, logoutUser } from "redux/actions/authActions";

import AdminPage from "components/pages/AdminPage";
import SupervisorPage from "components/pages/SupervisorPage";
import DistributorPage from "components/pages/DistributorPage";
import InvoicePage from "components/pages/InvoicePage";
import UserPage from "components/pages/UserPage";
import LeadPage from "components/pages/LeadPage";
import WatchLeadPage from "components/pages/WatchLeadPage";

import DrawerList from "components/partials/DrawerList";

import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  Badge,
  MenuItem,
  Menu,
  Drawer,
  ListItemText,
  Button,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "assets/logo.png";
import WatchUserPage from "components/pages/WatchUserPage";
import WatchDistributorPage from "components/pages/WatchDistributorPage";
import DownloadReport from "components/partials/user/DownloadReport";
import { setNotifications } from "redux/actions/userActions";
import { setTime } from "redux/actions/timeActions";

const drawerWidth = 240;

//User Layout Styles
const userLayoutStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: "#fff",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    width: "75px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    padding: theme.spacing(2),
  },
}));
let socket;

const UserLayout = ({ history, window, width }) => {
  //Get auth state
  const authState = useSelector((state) => state.auth);
  const userState = useSelector((state) => state.user);
  const timeState = useSelector((state) => state.time);

  const classes = userLayoutStyles({});
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      history.push("/login");
    }
    if (authState.forceReset) {
      history.push("/reset");
    }
    if (authState.user == null || authState.user === {}) {
      dispatch(setUserLoading());
    }
  }, [authState, history, dispatch]);

  useEffect(() => {
    socket = io("https://api.mota.technology");
    socket.on("validation-request", () => {
      socket.emit("validate", {
        token: authState.token,
      });
    });
    socket.on("notifications", ({ notifications }) => {
      dispatch(setNotifications(notifications));
    });

    return () => {
      socket.disconnect(true);
    };
  }, [authState, dispatch]);

  const [menu, setMenu] = useState(null);
  const [notif, setNotif] = useState(null);
  const [queryDate, setQueryDate] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setMenu(event.currentTarget);
  };
  const handleProfileMenuOMenuClose = () => {
    setMenu(null);
  };

  const handleNotificationClick = (notificationId) => {
    if (socket != null) {
      socket.emit("seen", { notificationId });
    }
  };

  const handleNotificationOpen = (event) => {
    setNotif(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotif(null);
  };
  const handleQueryDateClose = () => {
    setQueryDate(null);
  };
  const handleQueryDateOpen = (event) => {
    setQueryDate(event.currentTarget);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const menuId = "account-menu";
  const isMenuOpen = Boolean(menu);
  const notifId = "notif-menu";
  const isNotifOpen = Boolean(notif);
  const queryDateId = "query-menu";
  const isQueryDateOpen = Boolean(queryDate);
  const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  const today = new Date();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" className={classes.logo} />
          <Typography className={classes.title} variant="caption" noWrap>
            Programa de Lealtad
          </Typography>
          <div className={classes.grow} />
          <Button
            edge="end"
            aria-label="show query date selector"
            aria-controls={queryDateId}
            aria-haspopup="true"
            onClick={handleQueryDateOpen}
            color="inherit"
            endIcon={<DateRangeIcon />}
          >
            {`${months[timeState.month]} ${timeState.year}`}
          </Button>
          <IconButton
            aria-label="show new notifications"
            color="inherit"
            onClick={handleNotificationOpen}
          >
            <Badge
              badgeContent={userState.notifications?.length}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={menu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleProfileMenuOMenuClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(logoutUser());
          }}
        >
          Salir
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={notif}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id={notifId}
        keepMounted
        open={isNotifOpen}
        onClose={handleNotificationClose}
      >
        {userState.notifications?.map((notification) => (
          <MenuItem
            key={notification.createdAt}
            component={NavLink}
            exact
            to={notification.url}
            onClick={() => {
              handleNotificationClick(notification._id);
            }}
          >
            <ListItemText
              primary={notification.title}
              secondary={notification.description}
              className={classes.listItemText}
            />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={queryDate}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id={queryDateId}
        keepMounted
        open={isQueryDateOpen}
        onClose={handleQueryDateClose}
      >
        {[0, 1,2,3,4,5,6].map(i => {
          const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
          const month = months[d.getMonth()];
          return (<MenuItem
            key={i}
            selected={timeState.month === d.getMonth() && timeState.year === d.getFullYear()}
            onClick={() => {
              dispatch(setTime(d.getMonth(),d.getFullYear()));
              history.go(0);
            }}
          >
            {`${month} ${d.getFullYear()}`}
          </MenuItem>);
        })}
      </Menu>
      <Hidden smUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerList history={history} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerList history={history} />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.paper}>
          <Switch location={location}>
            {hasRole(authState.user, [Role.ADMIN]) && (
              <PrivateRoute
                path="/dashboard/distributor/:organization/:id"
                component={WatchDistributorPage}
              />
            )}
            {hasRole(authState.user, [Role.DISTRIBUTOR, Role.ADMIN]) && (
              <PrivateRoute
                path="/dashboard/seller/:organization/:id"
                component={WatchUserPage}
              />
            )}
            {hasRole(authState.user, [Role.ADMIN]) && (
              <PrivateRoute path="/dashboard" component={AdminPage} />
            )}
            {hasRole(authState.user, [Role.SELLER]) && (
              <PrivateRoute path="/dashboard" component={UserPage} />
            )}
            {hasRole(authState.user, [Role.DISTRIBUTOR]) && (
              <PrivateRoute path="/dashboard" component={DistributorPage} />
            )}
             {hasRole(authState.user, [Role.VERIFIER]) && (
              <PrivateRoute path="/dashboard" component={SupervisorPage} />
            )}
            {hasRole(authState.user, [Role.SELLER, Role.DISTRIBUTOR, Role.ADMIN]) && (
              <PrivateRoute path="/reports" component={DownloadReport} />
            )}
            {hasRole(authState.user, [Role.DISTRIBUTOR, Role.ADMIN]) && (
              <PrivateRoute
                path="/leads/:role/:organization/:id"
                component={WatchLeadPage}
              />
            )}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.DISTRIBUTOR,
              Role.SELLER,
            ]) && <PrivateRoute path="/leads" component={LeadPage} />}
            <PrivateRoute path="/invoice/:id" component={InvoicePage} />
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default UserLayout;
