import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  pos: {
    marginBottom: 12,
  },
  typography: {
    fontSize: "1.7rem",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  success: {
    color: "rgb(74,217,145)",
  },
  error: {
    color: "rgb(255,101,101)",
  },
}));

const SalesCard = ({ title, value, color, isNotCurrency }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="h1"
          className={`${classes.typography} ${classes[color]}`}
          component="h2"
          gutterBottom
        >
          {value != null ? isNotCurrency
            ? value.toLocaleString("en-US")
            : `$ ${value.toLocaleString("en-US")} USD` : null}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SalesCard;
