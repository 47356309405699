import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { setUserLoading, logoutUser } from "redux/actions/authActions";
import { setInvoices } from "redux/actions/invoiceActions";

import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import jwt_decode from "jwt-decode";
import setAuthToken from "utils/setAuthToken";
import axios from "utils/axios";

var hash = require('object-hash');

const initialState = {};

const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['user', 'category', 'lead']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose
  )
);

const persistor = persistStore(store);

const syncWithServer = async (store) => {
  const authState = store.getState().auth;
  if (authState && authState.isAuthenticated) {

    setAuthToken(authState.token);
    const decoded = jwt_decode(authState.token);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime || !authState.rememberMe) {
      store.dispatch(logoutUser());
      window.location.href = "./login";
    }
    //Verify with server just in case
    store.dispatch(setUserLoading());
  }
};

export { store, persistor, syncWithServer };