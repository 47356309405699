import { SET_CURRENT_USER, USER_LOADING, LOGIN_SUCCESS, PASSWORD_RESET, REMEMBER_ME, PASSWORD_CHANGED} from "redux/actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  token: localStorage.getItem("jwtToken"),
  loading: false,
  rememberMe: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        token: action.payload,
        forceReset: false
      }
    case REMEMBER_ME:
      return{
        ...state,
        rememberMe: action.payload
      }
    case PASSWORD_RESET:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        token: action.payload,
        forceReset: true
      }
    case PASSWORD_CHANGED:
        return {
          ...state,
          forceReset: false
        }
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}