import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSales } from "redux/actions/invoiceActions";

import Grid from "@material-ui/core/Grid";
import SalesCard from "components/partials/user/SalesCard";
import InvoiceForm from "components/partials/user/InvoiceForm";
import InvoiceTable from "components/partials/user/InvoiceTable";

const UserPage = ({ history }) => {
  const authState = useSelector((state) => state.auth);
  const invoiceState = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  // const selectefUser = useSelector(s => s.currentUSer)

  /*
   * s.currentUser :
   *   userID
   *   invoices
   *   roles
   *   sales
   */

  /**
   * auth.role == user {
   *  s.curremtUser = user
   * }
   *
   *  /dashborad
   * /dashboard/:org
   *
   */

  useEffect(() => {
    dispatch(getSales(authState.user.data.role, authState.user.data.id));
  }, [authState.user.data, dispatch]);

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/*Total amount*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Total de Ventas"}
              value={invoiceState.sales !== null? invoiceState.sales.TotalSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Reconocidas"}
              color={"success"}
              value={invoiceState.sales !== null? invoiceState.sales.AcceptedSales : 0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SalesCard
              title={"Ventas Pendientes"}
              color={"error"}
              value={invoiceState.sales !== null? invoiceState.sales.RejectedSales : 0}
            />
          </Grid>
        </Grid>
      </Grid>

      {/*Invoice form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Invoice Form*/}
          <Grid item xs={12} md={6}>
            <InvoiceForm />
          </Grid>
          {/*Invoice Table*/}
          <Grid item xs={12} md={6}>
            <InvoiceTable history={history} userId={authState.user.data.id} role={authState.user.data.role} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPage;
