import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setAlert } from "redux/actions/alertAction";
import { getCategories, updateCategory, deleteCategory, createCategory } from "redux/actions/categoryActions";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from './GenericRestfulTable';

const CategoryTable = () => {

  const dispatch = useDispatch();

  const categoryState = useSelector((state) => state.category);
  const categories = categoryState.categories;

  const columns = [
    {title: "id", field: "id", hidden: true},
    {title: "Nombre", field: "name"},
    {title: "Multiplicador", field: "multiplier", type: "numeric"}
  ]

  const handleRowUpdate = (newData, oldData, resolve) => {
    if(!newData.multiplier){
      newData.multiplier = 1;
    }
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    
    else{
      dispatch(updateCategory(resolve, oldData.id, newData.name, newData.multiplier));
    }
  }

  const handleRowAdd = (newData, resolve) => {
    if(!newData.multiplier){
      newData.multiplier = 1;
    }
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(createCategory(resolve, newData.name));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteCategory(resolve, oldData.id));
  }

  useEffect(() => {
    categories == null &&
      dispatch(getCategories());
  }, [dispatch, categories]);

  return (
    <RestfulTable
      title={"Categorías"}
      data={categories}
      dataColumns={columns}
      handleRowAdd={handleRowAdd}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
    />
  );
};

export default CategoryTable;
